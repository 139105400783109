import {
  type ClientLoaderFunctionArgs,
  json,
  redirect,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { type AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';

import { type DtoSingleGamePackResponse } from '@lp-lib/api-service-client/public';
import { appendBlockReferenceOutputs } from '@lp-lib/game/src/block-outputs';

import { useLearningAnalytics } from '../analytics/learning';
import { useUserAnalytics } from '../analytics/user';
import { RequireActivation } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import { setRiveWasmUrl } from '../components/GameV2/animation/riveWasm';
import { readGuestUserAccess } from '../components/GameV2/apis/GuestUser';
import { PlayCursor } from '../components/GameV2/PlayCursor';
import { Playground } from '../components/GameV2/Playground';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider, useUserGetter } from '../components/UserContext';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { fromDTOBlock } from '../utils/api-dto';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';
import { isMobile } from '../utils/user-agent';

setAPIServiceClientSecureToken();
setRiveWasmUrl();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  const searchParams = new URL(action.request.url).searchParams;
  const stackId = searchParams.get('stack-id');
  let overworldUrl = `/game-packs/${id}/overworld`;
  if (stackId) {
    overworldUrl = `/stacks/${stackId}/overworld`;
  }

  let resp: AxiosResponse<DtoSingleGamePackResponse>;
  try {
    resp = await tokenWithRedirect(
      () =>
        apiService.gamePack.getGamePackById(id, {
          blocks: true,
          games: true,
          progression: 'authenticating-user',
          guest: readGuestUserAccess() === 'enabled',
        }),
      action.request.url,
      {
        // for now, require authentication. in the future, we may permit unauthenticated access...
        requireAuthentication: true,
        preferRedirect: 'login',
      }
    );
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }

  if (!resp.data.progression) {
    throw redirect(overworldUrl);
  }

  const gameIdToPlay = action.params.gameId;

  if (gameIdToPlay) {
    const game = resp.data.games?.find((game) => game.id === gameIdToPlay);
    if (!game || !resp.data.progression.progress?.[game.id]) {
      if (stackId) {
        throw redirect(`/game-packs/${id}/play?stack-id=${stackId}`);
      } else {
        throw redirect(`/game-packs/${id}/play`);
      }
    }
  }

  return {
    gamePackBundle: resp.data,
    gameIdToPlay,
    overworldUrl,
  };
};

function PlaygroundInternal() {
  const { gamePackBundle, gameIdToPlay, overworldUrl } =
    useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();
  const getUser = useUserGetter();
  const analytics = useLearningAnalytics();

  const getLearningAnalytics = useLiveCallback(() => {
    return analytics;
  });

  const cursor = useMemo(() => {
    let c = PlayCursor.FromGamePackResponse(gamePackBundle, gameIdToPlay);
    if (!c.currentBlock()) {
      // if we don't have a block, then assume the game is already completed,
      // and the user is playing again. Reset the cursor to the beginning of the
      // minigame.
      c = c.withRestartMinigame();
    }
    return c;
  }, [gameIdToPlay, gamePackBundle]);

  const blockOutputs = useMemo(() => {
    const { progression, blocks } = gamePackBundle;
    const outputs = progression?.blockOutputs;

    if (!outputs || Object.keys(outputs).length === 0) return null;
    if (!blocks) return outputs;

    // append blockReference outputs to the block outputs.
    const blockRefMap = new Map(
      blocks.map((block) => [block.id, fromDTOBlock(block)])
    );
    return appendBlockReferenceOutputs(outputs, blockRefMap);
  }, [gamePackBundle]);

  const handleClose = useLiveCallback(() => {
    navigate(overworldUrl);
  });

  // Lock body scroll, this is important for mobile drag & drop
  useEffect(() => {
    if (!isMobile()) return;
    const original = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = original;
    };
  }, []);

  return (
    <Playground
      cursor={cursor}
      initialBlockOutputs={blockOutputs}
      onClose={handleClose}
      // return users back to the overworld on continue.
      onMinigameContinue={handleClose}
      getUser={getUser}
      getLearningAnalytics={getLearningAnalytics}
    />
  );
}

export const Component = () => {
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <RequireActivation />,
  ];

  return (
    <ProvidersList providers={providers}>
      <PlaygroundInternal />
    </ProvidersList>
  );
};
